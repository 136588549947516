enum DeliveryOrderStatus {
  "NEW" = 0,
  "ASSIGNED" = 1,
  "COLLECTED" = 2,
  "DELIVERED" = 3,
  "IMPORTED" = 5,
  "BILLED" = 6,
  "STARTED" = 8,
  "ARCHIVED" = 9,
}
export default DeliveryOrderStatus;
