import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Ajax from "./lib/ajax";
import Util from "./lib/util";
import vuetify from "./plugins/vuetify";
import "./plugins/veevalidate";
import "./sass/app.scss";
import * as filters from "./lib/filters";

// filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;

Ajax.post("/DriverAccount/Profile")
  .then((data: any) => {
    Util.account.isLogin = data != null;
  })
  .catch((err) => console.log(err))
  .finally(() => {
    Util.remoteServiceValid = true;
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  });
