import ajax from "../../lib/ajax";
import util from "../../lib/util";
import { Store, Module, ActionContext } from "vuex";
interface SessionState {
  user: any;
}
class SessionStore implements Module<SessionState, any> {
  namespaced = true;
  state = {
    user: null,
  };
  actions = {
    async init(content: ActionContext<SessionState, any>) {
      let rep = await ajax.post("/DriverAccount/Profile");
      content.state.user = rep.data.result.user;
    }
  };
}
const session = new SessionStore();
export default session;
