import axios from "axios";
import appconst from "./appconst";
import Util from "./util";
import Vue from "vue";
import { EventBus } from "./event";

const ajax = axios.create({
  baseURL: appconst.remoteServiceBaseUrl,
  timeout: 30000,
});
ajax.interceptors.request.use(
  function(config) {
    if (Util.getToken()) {
      config.headers.common["Authorization"] = "Bearer " + Util.getToken();
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
let vm = new Vue({});
ajax.interceptors.response.use(
  (respon) => {
    return respon;
  },
  (error) => {
    if (
      !!error.response &&
      !!error.response.data.error &&
      (!!error.response.data.error.message ||
        !!error.response.data.error.details)
    ) {
      EventBus.$emit("on-error", {
        title: "Error",
        content:
          error.response.data.error.message ||
          error.response.data.error.details,
      });
    }
    return Promise.reject(error);
  }
);
export default ajax;
