
export class TripLevel {
  LevelName: string
  Min: number
  Max: number
  Point: number
  TripCount: number
}

export class TripLorryType {
  LorryTypeName:string;
  DriverDOCount:number;
  Levels:Array<TripLevel>;
}
export class TripSummary {
  LorryTypes: Array<TripLorryType>
}