import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import session from "./modules/session";
import app from "./modules/app";
import driver from "./modules/driver";
import deliveryOrder from "./modules/delivery-orders";

const store = new Vuex.Store({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {},
  modules: {
    app,
    session,
    driver,
    deliveryOrder,
  }
});

export default store;
