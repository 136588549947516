import config from "./../../.env.json";

const AppConsts = {
  userManagement: {
    defaultAdminUserName: "admin",
  },
  localization: {
    defaultLocalizationSourceName: "SwiftSupplyChain",
  },
  authorization: {
    encrptedAuthTokenName: "enc_auth_token",
  },
  appBaseUrl: config.appBaseUrl.endsWith("/")
    ? config.appBaseUrl.slice(0, -1)
    : config.appBaseUrl,
  remoteServiceBaseUrl: config.remoteServiceBaseUrl.endsWith("/")
    ? config.remoteServiceBaseUrl.slice(0, -1)
    : config.remoteServiceBaseUrl,
  clientName: config.clientName,
};
export default AppConsts;
