
import moment from "./../plugins/moment";
import Cookies from 'js-cookie';
import appconst from './appconst';

interface AccountState {
  isLogin: boolean;
}

class Util {
  account: AccountState = {
    isLogin: false,
  };
  remoteServiceValid = false;
  remoteServiceBaseUrl = appconst.remoteServiceBaseUrl;
  clientName = appconst.clientName;

  getToken() : string {
    const key = "Swift.AuthToken";
    return Cookies.get(key);
  }
  setToken(value, expiredDays) {
    const key = "Swift.AuthToken";
    Cookies.set(key, value, { expires: expiredDays });
  }
}
const util = new Util();
export default util;
