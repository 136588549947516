import _ from "lodash";
import moment from "moment";
import { Store, Module, ActionContext } from "vuex";
import ListModule from "./list-module";
import ListState from "./list-state";
import DeliveryOrder from "../entities/delivery-order";
import Ajax from "../../lib/ajax";
import PageResult from "@/store/entities/page-result";
import ListMutations from "./list-mutations";
import DeliveryOrderStatus from "../enums/delivery-order";

interface DeliveryOrderState extends ListState<DeliveryOrder> {
  editDeliveryOrder: DeliveryOrder;
}
class DeliveryOrderModule extends ListModule<
  DeliveryOrderState,
  any,
  DeliveryOrder
> {
  state = {
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
    list: new Array<DeliveryOrder>(),
    loading: false,
    editDeliveryOrder: DeliveryOrder,
    messages: String,
  };
  actions = {
    async getAll(context: ActionContext<DeliveryOrderState, any>) {
      context.state.loading = true;
      let response = await Ajax.post("/DriverAccount/PastDeliveryOrders");
      context.state.loading = false;
      let result = response.data.result;
      let list = result.DeliveryOrders as Array<DeliveryOrder>;
      context.state.list = _.chain(list)
        .filter((item) => {
          return (
            item.Status !== DeliveryOrderStatus.NEW &&
            item.Status !== DeliveryOrderStatus.ASSIGNED &&
            item.Status !== DeliveryOrderStatus.COLLECTED &&
            item.Status !== DeliveryOrderStatus.STARTED
          );
        })
        .orderBy(["DeliveryDate", "DriverPriority"], ["asc", "asc"])
        .value();
    },
    async getToday(context: ActionContext<DeliveryOrderState, any>) {
      context.state.loading = true;
      let response = await Ajax.post("/DriverAccount/TodayDeliveryOrders");
      context.state.loading = false;
      let result = response.data.result;
      let list = result.DeliveryOrders as Array<DeliveryOrder>;
      context.state.list = _.chain(list)
        .filter((item) => {
          return (
            item.Status === DeliveryOrderStatus.ASSIGNED ||
            item.Status === DeliveryOrderStatus.COLLECTED || 
            item.Status === DeliveryOrderStatus.STARTED 
          );
        })
        .map((item) => ({
          ...item,
          DeliveryDate: moment(item.DeliveryDate)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toDate(),
        }))
        .orderBy(["DeliveryDate", "DriverPriority"], ["asc", "asc"])
        .value();
    },
    async assigned(
      context: ActionContext<DeliveryOrderState, any>,
      payload: any
    ) {
      let response = await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}`
      );
      let result = response.data.result;
      let doFromDb = result.DeliveryOrder as DeliveryOrder;

      if (!doFromDb) {
        context.commit("setMessage", "D.O not founded.");
        return false;
      } else if (doFromDb.Status != payload.data.Status) {
        context.commit("setMessage", "D.O status not updated, reload page.");
        return false;
      }

      await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}/assigned`
      );
      return true;
    },
    async started(
      context: ActionContext<DeliveryOrderState, any>,
      payload: any
    ) {
      let response = await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}`
      );
      let result = response.data.result;
      let doFromDb = result.DeliveryOrder as DeliveryOrder;

      if (!doFromDb) {
        context.commit("setMessage", "D.O not founded.");
        return false;
      } else if (doFromDb.Status != payload.data.Status) {
        context.commit("setMessage", "D.O status not updated, reload page.");
        return false;
      }

      await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}/started`
      );
      return true;
    },
    async collected(
      context: ActionContext<DeliveryOrderState, any>,
      payload: any
    ) {
      let response = await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}`
      );
      let result = response.data.result;
      let doFromDb = result.DeliveryOrder as DeliveryOrder;

      if (!doFromDb) {
        context.commit("setMessage", "D.O not founded.");
        return false;
      } else if (doFromDb.Status != payload.data.Status) {
        context.commit("setMessage", "D.O status not updated, reload page.");
        return false;
      }

      await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}/collected`
      );
      return true;
    },
    async delivered(
      context: ActionContext<DeliveryOrderState, any>,
      payload: any
    ) {
      let response = await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}`
      );
      let result = response.data.result;
      let doFromDb = result.DeliveryOrder as DeliveryOrder;

      if (!doFromDb) {
        context.commit("setMessage", "D.O not founded.");
        return false;
      } else if (doFromDb.Status != payload.data.Status) {
        context.commit("setMessage", "D.O status not updated, reload page.");
        return false;
      }

      let data: DeliveryOrder = payload.data;

      const formData = new FormData();
      formData.append("DriverDoNo", payload.data.DriverDoNo || "");
      formData.append("SupplierDoNo", payload.data.SupplierDoNo || "");
      formData.append("TicketNo", payload.data.TicketNo || "");
      formData.append("TicketCost", payload.data.TicketCost);
      formData.append("TicketQty", payload.data.TicketQty);
      formData.append("BehalfDoNo", payload.data.BehalfDoNo || "");
      formData.append("DriverDoImg", payload.data.DriverDoImg);
      formData.append("SupplierDoImg", payload.data.SupplierDoImg);
      formData.append("TicketImg", payload.data.TicketImg);
      formData.append("BehalfDoImg", payload.data.BehalfDoImg);
      formData.append("TollCount", payload.data.TollCount);
      formData.append(
        "IsDocSupplierDoCollect",
        data.IsDocSupplierDoCollect ? "1" : "0"
      );
      formData.append(
        "IsDocDriverDoCollect",
        data.IsDocDriverDoCollect ? "1" : "0"
      );
      formData.append(
        "IsDocBehalfOfDoCollect",
        data.IsDocBehalfOfDoCollect ? "1" : "0"
      );
      formData.append(
        "IsDocTicketCollect",
        data.IsDocTicketCollect ? "1" : "0"
      );
      await Ajax.post(
        `/DriverAccount/DeliveryOrders/${payload.data.Id}/delivered`,
        formData
      );
      return true;
    },
  };
  mutations = {
    setCurrentPage(state: DeliveryOrderState, page: number) {
      state.currentPage = page;
    },
    setPageSize(state: DeliveryOrderState, pagesize: number) {
      state.pageSize = pagesize;
    },
    edit(state: DeliveryOrderState, DeliveryOrder: DeliveryOrder) {
      state.editDeliveryOrder = DeliveryOrder;
    },
    setMessage(state, payload) {
      state.messages = payload;
    },
  };
}
const customerModule = new DeliveryOrderModule();
export default customerModule;
