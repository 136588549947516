import DeliveryOrderItem from "./delivery-order-item";
import DeliveryOrderStatus from "./../enums/delivery-order";
import Entity from "./entity";

export default class DeliveryOrder extends Entity<number> {
  Id: number;
  DeliverySiteId: number;
  CollectSiteId: number;
  TransporterId: number;
  LorryTypeId: number;
  LorryId: number;
  CustomerId: number;
  SupplierId: number;
  TicketCost: number;
  AddrType: number;

  RunningNumber: string;
  ContactPerson: string;
  ContactTelNo: string;
  ContactPerson2: string;
  ContactTelno2: string;

  DeliverySiteName: string;
  CollectSiteName: string;
  TarnsporterName: string;
  CustomerName: string;
  CustomerAddr: string;
  CustomerCode: string;
  CustomerTelNo: string;
  CustomerFax: string;
  SupplierName: string;
  SupplierAddr: string;
  SupplierCode: string;
  SupplierTelNo: string;
  SupplierFax: string;
  DeliveryAddr: string;
  LorryPlateNumber: string;
  LorryTypeName: string;
  Remarks: string;
  DriverPriority: number;

  DocDriverDoNo: string;
  DocBehalfOfDoNo: string;
  DocSupplierDoNo: string;
  TicketNo: string;
  TicketSupplierName: string;

  TicketImgUrl: string;
  DriverDoImgUrl: string;
  SupplierDoImgUrl: string;
  BehalfOfDoImgUrl: string;

  Items: Array<DeliveryOrderItem>;
  Item: DeliveryOrderItem;
  Status: DeliveryOrderStatus;
  DeliveredTime: Date;
  DeliveryDate: Date;

  TollCount: number;
  IsDocSupplierDoCollect: boolean;
  IsDocDriverDoCollect: boolean;
  IsDocBehalfOfDoCollect: boolean;
  IsDocTicketCollect: boolean;
  DeliveredTimeDuration: string;
  DeliveredTimeFrom?: Date;
  DeliveredTimeTo?: Date;
}
