import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Util from "./../lib/util";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/main",
    name: "main",
    component: () => import("../views/main.vue"),
    redirect: "/main/task-uncompleted",
    children: [
      {
        path: "task-uncompleted",
        name: "TaskUncompleted",
        component: () => import("../views/task-uncompleted.vue"),
      },
      {
        path: "task-completed",
        name: "TaskCompleted",
        component: () => import("../views/task-completed.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/profile.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  if (!Util.account.isLogin && to.name !== "login")
    next({
      name: "login",
    });
  else if (Util.account.isLogin && to.name === "login")
    next({
      name: "main",
    });
  else next();
});
