import Vue from "vue";
import Vuetify from "vuetify/lib";
import { VTextField, VSelect, VTextarea, VBtn, VIcon } from "vuetify/lib";

Vue.use(Vuetify);
Vue.component("DInput", {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: String,
      default: "auto"
    }
  }
});
Vue.component("DSelect", {
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: String,
      default: "auto"
    }
  }
});
Vue.component("DTextarea", {
  extends: VTextarea,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      type: String,
      default: "auto"
    }
  }
});
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#007bff",
        secondary: "#6c757d",
        error: "#dc3545",
        info: "#17a2b8",
        success: "#28a745",
        warning: "#ffc107"
      }
    }
  }
});
