import Entity from "./entity";
export default class Driver extends Entity<number> {
  LorryPlateNumber:string;
  LorryTypeName:string;
  Name:string;
  ShortName:string;
  Nric:string;
  Email:string;
  TelNo:string;
}
