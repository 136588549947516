import moment from "./../plugins/moment";
export function datetimeReadable(
  value: string,
  format = "DD-MMM-YYYY hh:mm A"
) {
  if (!value) return "";

  let date = moment(value);
  if (!date.isValid()) return "";

  return date.format(format);
}

export function emptyReadable(value: string) {
  if (!value) return "-";
  return value;
}
