import Util from "../../lib/util";
import Vue from "vue";
import { Store, Module, ActionContext } from "vuex";
import Vuex from "vuex";
import ajax from "../../lib/ajax";
import appconst from "../../lib/appconst";
Vue.use(Vuex);
interface AppState {
  color: any;
}
class AppModule implements Module<AppState, any> {
  namespaced = true;
  state = {
    color: null,
  };
  mutations = {
    logout(state: AppState) {
      localStorage.clear();
      sessionStorage.clear();
    },
  };
  actions = {
    async login(content: ActionContext<AppState, any>, payload: any) {
      let rep = await ajax.post("/DriverAccount/Login", payload.data);

      let expiredDays = rep.data.result.ExpiredDays;
      let token = rep.data.result.AccessToken;

      Util.setToken(token, expiredDays);
      Util.account.isLogin = true;
    },
    async logout(content: ActionContext<AppState, any>, payload: any) {
      Util.setToken("", -1);
      Util.account.isLogin = false;
    },
  };
}
const appModule = new AppModule();
export default appModule;
