




















import Vue from "vue";
import { EventBus } from "./lib/event.js";

export default Vue.extend({
  name: "App",

  components: {},

  data: () => ({
    snackbarModel: {
      show: false,
      title: "",
      content: "",
      color: "",
    },
  }),
  created() {
    EventBus.$on("on-error", ({ title, content }) => {
      this.snackbarModel.show = true;
      this.snackbarModel.color = "error";
      this.snackbarModel.title = title;
      this.snackbarModel.content = content;
    });
    EventBus.$on("on-success", ({ title, content }) => {
      this.snackbarModel.show = true;
      this.snackbarModel.color = "success";
      this.snackbarModel.title = title;
      this.snackbarModel.content = content;
    });
  },
});
