import { Store, Module, ActionContext } from "vuex";
import moment from "moment";
import ListModule from "./list-module";
import ListState from "./list-state";
import Driver from "../entities/driver";
import {TripSummary} from "../entities/trip-summary";
import Ajax from "../../lib/ajax";
import PageResult from "@/store/entities/page-result";
import ListMutations from "./list-mutations";

interface DriverState extends ListState<Driver> {
  profile: Driver;
}
interface TripState extends ListState<TripSummary> {
  trip: TripSummary;
}
class DriverModule extends ListModule<DriverState, any, Driver> {
  state = {
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
    list: new Array<Driver>(),
    loading: false,
    profile: new Driver(),
    trip: new TripSummary(),
  };
  actions = {
    async profile(context: ActionContext<DriverState, any>){
      context.state.loading = true;
      let response = await Ajax.post("/DriverAccount/Profile");
      context.state.loading = false;
      context.state.profile = response.data.result as Driver;
    },
    async trip(context: ActionContext<TripState, any>){
      const datefrom = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
      const dateto = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');

      let response = await Ajax.post("/DriverAccount/TripCounts", {
        DateFrom: datefrom,
        DateTo:dateto,
      });
      let result = response.data.result;
      let trip = result.LorryTypes as TripSummary;

      context.state.trip = trip;
    }
  };
  mutations = {
    setCurrentPage(state: DriverState, page: number) {
      state.currentPage = page;
    },
    setPageSize(state: DriverState, pagesize: number) {
      state.pageSize = pagesize;
    },
  };
}
const customerModule = new DriverModule();
export default customerModule;
